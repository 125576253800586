import React from 'react';
import moment from 'moment';
import ErrorBoundary from 'react-error-boundaries';
import { Route, Switch } from 'react-router-dom';
import Routes from '../routes';
import * as routeComponents from './common/routes';
import { StylesProvider } from '@material-ui/styles';
import SwayMeeAppRoot from './SwayMeeAppRoot';
import AuthLoadingPage from './AuthLoadingPage';
import NoMatch from './common/NoMatch';
import { ComponentLoader } from './common/ComponentLoader/ComponentLoader';
import AppErrorPage from './common/AppErrorPage/AppErrorPage';
import { Logger } from '../api/logging.api';
import { withAppAlert } from './common/SnackBarAlert/AppAlertHOC';

const LazyLogIn = React.lazy(() => import('./auth/LogIn'));
const LazySignUp = React.lazy(() => import('./auth/SignUp'));
const LazyPasswordReset = React.lazy(() => import('./auth/PasswordReset'));
const LazySignUpFlow = React.lazy(() => import('./flows/SignUpFlow'));
const LazyAuthUserManagement = React.lazy(
  () => import('./auth/AuthUserManagement')
);

function App() {
  const onError = async (error: Error, errorInfo: any, props: any) => {
    (await Logger.getInstance()).error(
      'SwayMee App error boundary violation',
      {
        name: 'APPLICATION_ERROR_BOUNDARY',
        source: 'SwayMee App Root',
        value: 'APPLICATION_CRASH',
        logTime: moment().unix(),
      },
      {
        key: error.name,
        message: error.message,
        stackTrace: error.stack,
      }
    );
  };

  return (
    <ErrorBoundary onError={onError} FallbackComponent={AppErrorPage}>
      <StylesProvider injectFirst>
        <AuthLoadingPage>
          <React.Suspense fallback={<ComponentLoader loaded={false} />}>
            <Switch>
              <Route path={Routes.LogIn} component={LazyLogIn} />

              <Route path={Routes.SignUp} component={LazySignUp} />

              <Route
                path={Routes.PasswordReset}
                component={LazyPasswordReset}
              />

              <Route path={Routes.SignUpFlow} component={LazySignUpFlow} />

              <routeComponents.PrivateRoute
                path={Routes.AppRoot}
                component={SwayMeeAppRoot}
              />

              <Route
                path={Routes.AuthUserManagement}
                component={LazyAuthUserManagement}
              />

              <Route component={NoMatch} />
            </Switch>
          </React.Suspense>
        </AuthLoadingPage>
      </StylesProvider>
    </ErrorBoundary>
  );
}

export default withAppAlert(App);
