import { gql } from '@apollo/client';
import {
  CompanyServiceError,
  UserProfileServiceError,
} from './userProfile.errors';
import client from './common.api';
import {
  UserProfileResult,
  UserProfile,
  VerificationStatus,
  MutationResponse,
  Company,
  WekRisk,
  BusinessUserProfileImagePresignedUrls,
} from './types';
export { user as hooks } from './hooks';

export type UserProfileMutationResponse = MutationResponse & {
  userProfile?: UserProfile;
};

const BUSINESS_INFO_ALL_FIELDS =
  'companyName,businessAlias,yearEstablished,industry,homepage';
const BUSINESS_VERIFICATION_ALL_FIELDS = 'status,filename,result';
const BUSINESS_USER_PROFILE_LOGO_ALL_FIELDS = 'readUrl, writeUrl';
const ALL_SELECTED_COMPANY_PERMISSIONS =
  'isOwner,canEditPromotions,canStopPromotions,canEditBilling,canDeleteBilling,canEditCatalog,canDeleteCatalog,canEditCompany,canManageTeam';
const COMPANY_MINUS_TEAM_MEMBERS_FIELDS = `companyID,businessInfo{${BUSINESS_INFO_ALL_FIELDS}},verification{${BUSINESS_VERIFICATION_ALL_FIELDS}},logo{${BUSINESS_USER_PROFILE_LOGO_ALL_FIELDS}},permissions{${ALL_SELECTED_COMPANY_PERMISSIONS}}`;
export const USER_PROFILE_MINUS_SELECTABLE_COMPANIES_FIELDS = `userId,email,selectedCompany{${COMPANY_MINUS_TEAM_MEMBERS_FIELDS}},onboarded`;
const BUSINESS_USER_PROFILE_LOGO_PRESIGNED_URL_ALL_FIELDS = `readUrl, writeUrl`;
const BUSINESS_USER_PROFILE_HOMEPAGE_WEBRISK = `isSafe, threat`;

export type CreateUserProfileWithCompanyInput = {
  email: string;
  businessInfo: {
    companyName: string;
    businessAlias?: String;
    yearEstablished: number;
    industry: string;
    homepage?: string;
  };
};

export async function createUserProfileWithCompany(
  input: CreateUserProfileWithCompanyInput
): Promise<UserProfile | null> {
  type CreateUserProfileMutation = {
    createUserProfileWithCompany: UserProfileMutationResponse;
  };
  const mutation = gql`
    mutation CreateUserProfileMutation ($input: CreateUserProfileWithCompanyInput!) {
      createUserProfileWithCompany(input: $input) {
					success
					message
          userProfile {
              __typename
              ${USER_PROFILE_MINUS_SELECTABLE_COMPANIES_FIELDS}
          }
      }
    }
  `;
  const variables = {
    input,
  };
  const result = await client.mutate<CreateUserProfileMutation>({
    mutation,
    variables,
  });
  const model = result.data?.createUserProfileWithCompany;
  if (!model?.success) {
    throw new UserProfileServiceError(model?.message);
  }
  return model?.userProfile ?? null;
}

export type CreateUserProfileWithTeamInput = {
  email: string;
  accessCode: string;
};

export async function createUserProfileWithTeam(
  input: CreateUserProfileWithTeamInput
): Promise<UserProfile | null> {
  type CreateUserProfileMutation = {
    createUserProfileWithCompany: UserProfileMutationResponse;
  };
  const mutation = gql`
    mutation CreateUserProfileMutation ($input: CreateUserProfileWithTeamInput!) {
      createUserProfileWithTeam(input: $input) {
					success
					message
          userProfile {
              __typename
              ${USER_PROFILE_MINUS_SELECTABLE_COMPANIES_FIELDS}
          }
      }
    }
  `;
  const variables = {
    input,
  };
  const result = await client.mutate<CreateUserProfileMutation>({
    mutation,
    variables,
  });
  const model = result.data?.createUserProfileWithCompany;
  if (!model?.success) {
    throw new UserProfileServiceError(model?.message);
  }
  return model?.userProfile ?? null;
}

export async function getUserProfile(): Promise<UserProfileResult> {
  type UserProfileQuery = {
    userProfile: UserProfileResult;
  };
  const query = gql`
    query GetUserProfile {
      userProfile {
        __typename
        ... on UserProfile {
          ${USER_PROFILE_MINUS_SELECTABLE_COMPANIES_FIELDS}
        }
        ... on UserProfileNotFound {
          reason
        }
      }
    }
  `;
  const result = await client.query<UserProfileQuery>({
    query,
    // TODO: once we pul fetching of this data in react should not need this line
    fetchPolicy: 'no-cache',
  });

  return result.data?.userProfile;
}

export type UpdateBusinessInfoInput = {
  companyID: string;
  companyName: string;
  businessAlias?: string;
  yearEstablished: number;
  industry: string;
  homepage?: string;
};

export type UpdateBusinessInfoMutationResponse = MutationResponse & {
  company?: Company;
};

export async function updateCompanyBusinessInfo(
  info: UpdateBusinessInfoInput
): Promise<Company | null> {
  type UpdateBusinessInfoMutation = {
    updateBusinessInfo: UpdateBusinessInfoMutationResponse;
  };
  const mutation = gql`
    mutation UpdateBusinessInfoMutation($businessInfo: UpdateBusinessInfoInput!) {
      updateBusinessInfo(businessInfo: $businessInfo) {
				success
				message
        company {
            __typename
            ${COMPANY_MINUS_TEAM_MEMBERS_FIELDS}
        }
      }
    }
  `;
  const variables = {
    businessInfo: {
      ...info,
      businessAlias: info.businessAlias ?? '',
    },
  };
  const result = await client.mutate<UpdateBusinessInfoMutation>({
    mutation,
    variables,
  });
  const model = result.data?.updateBusinessInfo;
  if (!model?.success) {
    throw new CompanyServiceError(model?.message);
  }
  return model?.company ?? null;
}

export type UpdateBusinessVerificationStatusInput = {
  companyID: string;
  status: VerificationStatus;
};

export type UpdateBusinessVerificationStatusMutationResponse =
  MutationResponse & {
    company?: Company;
  };

export async function updateBusinessVerificationStatus({
  status,
  companyID,
}: UpdateBusinessVerificationStatusInput): Promise<Company | null> {
  type UpdateBusinessVerificationStatusMutation = {
    updateBusinessVerificationStatus: UpdateBusinessVerificationStatusMutationResponse;
  };
  const mutation = gql`
    mutation UpdateBusinessVerificationStatusMutation ($verification: UpdateBusinessVerificationStatusInput!) {
      updateBusinessVerificationStatus(verification: $verification) {
        company {
            __typename
            ${COMPANY_MINUS_TEAM_MEMBERS_FIELDS}
        }
      }
    }
  `;
  const variables = {
    verification: {
      status: status,
      companyID,
    },
  };
  const result = await client.mutate<UpdateBusinessVerificationStatusMutation>({
    mutation,
    variables,
  });
  return result.data?.updateBusinessVerificationStatus.company ?? null;
}

export async function updateUserOnboarded(
  onboarded: boolean
): Promise<UserProfile | null> {
  type UpdateUserOnboardedMutation = {
    updateUserOnboarded: UserProfileMutationResponse;
  };
  const mutation = gql`
    mutation UpdateUserOnboardedMutation ($input: UpdateUserOnboardedInput!) {
      updateUserOnboarded(input: $input) {
        userProfile {
          __typename
          ${USER_PROFILE_MINUS_SELECTABLE_COMPANIES_FIELDS}
        }
      }
    }
  `;
  const variables = {
    input: {
      onboarded,
    },
  };

  const result = await client.mutate<UpdateUserOnboardedMutation>({
    mutation,
    variables,
  });
  return result.data?.updateUserOnboarded.userProfile ?? null;
}

export async function getVerificationDocumentPreSignedUrl(
  file: File
): Promise<string | null> {
  type GetVerificationDocumentPreSignedUrlQuery = {
    verificationDocumentPreSignedUrl: string;
  };
  const query = gql`
    {
      verificationDocumentPreSignedUrl(fileName: "${file.name}", contentType: "${file.type}")
    }
  `;
  const result = await client.query<GetVerificationDocumentPreSignedUrlQuery>({
    query,
  });
  return result.data?.verificationDocumentPreSignedUrl ?? null;
}

export async function getUriWebRisk(uri: string): Promise<WekRisk | null> {
  type GetUriWebRiskQuery = {
    getUriWebRisk: WekRisk;
  };
  const query = gql`
	{
		getUriWebRisk(uri: "${uri}") {
			${BUSINESS_USER_PROFILE_HOMEPAGE_WEBRISK}
		}
	}
	`;
  const result = await client.query<GetUriWebRiskQuery>({
    query,
  });
  return result.data?.getUriWebRisk ?? null;
}

type GetProfileImagePresignedUrlInput = {
  filenameWithExt: string;
  contentType: string;
};

export async function getBusinessProfileImagePreSignedUrl(
  fileInfo: GetProfileImagePresignedUrlInput
): Promise<BusinessUserProfileImagePresignedUrls | null> {
  type getBusinessProfileImagePreSignedUrlQuery = {
    getProfileImagePreSignedUrl: BusinessUserProfileImagePresignedUrls;
  };
  const query = gql`
		query BusinessLogoPreSignedUrl($getProfileImagePresignedUrlInput: GetProfileImagePresignedUrlInput!){
      getProfileImagePreSignedUrl(input: $getProfileImagePresignedUrlInput) {
				${BUSINESS_USER_PROFILE_LOGO_PRESIGNED_URL_ALL_FIELDS}
			}
    }
	`;
  const variables = {
    getProfileImagePresignedUrlInput: fileInfo,
  };
  const result = await client.query<getBusinessProfileImagePreSignedUrlQuery>({
    query,
    variables,
  });

  return result.data?.getProfileImagePreSignedUrl ?? null;
}
