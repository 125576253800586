import { call, put, takeLeading, all, select } from 'redux-saga/effects';
import _isEqual from 'lodash/isEqual';
import { ActionType } from 'deox';
import * as actions from './userProfile.actions';
import * as selectors from './userProfile.selectors';
import { getCurrentUser } from '../../firebaseHelpers/firebaseHelpers';
import { userProfileApi, Types } from '../../api';
import {
  UserProfileApiRequestTypes,
  createUpdate,
} from './userProfile.constants';
import { ApiCallStatus, LocalStorageKeys } from '../shared/shared.constants';
import {
  uploadDataUri,
  getTimeStampedFileInfoFromDataUrl,
  FileInfo,
  uploadFile,
} from '../../utils/FileHelpers';
import { isBlankOrUndefined } from '../../utils/StringHelpers';
import { LocalStorage } from '../../utils/LocalStorage';

function* createUserProfile(
  action: ActionType<typeof actions.createUserProfile>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.CreateUserProfile, {
          status: ApiCallStatus.Pending,
        })
      )
    );
    const currentUser = getCurrentUser();
    if (currentUser) {
      // note that we potentially have wrong value in here
      let userProfile: Types.UserProfile | null = LocalStorage.getItem(
        LocalStorageKeys.INITIAL_USER_PROFILE
      );
      if (!userProfile || userProfile.userId !== currentUser.uid) {
        const result: Types.UserProfileResult = yield call(
          userProfileApi.getUserProfile
        );
        if (result.__typename !== 'UserProfileNotFound') {
          userProfile = result;
        } else {
          userProfile = null;
        }
        if (!userProfile) {
          if (actions.isCreateUserProfileWithTeamRequest(action.payload)) {
            userProfile = yield call(userProfileApi.createUserProfileWithTeam, {
              email: currentUser.email,
              accessCode: action.payload.accessCode,
            } as userProfileApi.CreateUserProfileWithTeamInput);
          } else {
            userProfile = yield call(
              userProfileApi.createUserProfileWithCompany,
              {
                email: currentUser.email,
                businessInfo: {
                  companyName: action.payload.businessInfo.companyName,
                  industry: action.payload.businessInfo.industry,
                  yearEstablished: action.payload.businessInfo.yearEstablished,
                  businessAlias: action.payload.businessInfo.businessAlias,
                  homepage: action.payload.businessInfo.homepage,
                },
              } as userProfileApi.CreateUserProfileWithCompanyInput
            );
          }
        }
      }
      if (
        !actions.isCreateUserProfileWithTeamRequest(action.payload) &&
        businessInfoNeedsUpdate(
          userProfile!.selectedCompany!.businessInfo,
          action.payload.businessInfo
        )
      ) {
        userProfile = yield call(userProfileApi.updateCompanyBusinessInfo, {
          companyID: userProfile?.selectedCompany?.companyID,
          companyName: action.payload.businessInfo.companyName,
          industry: action.payload.businessInfo.industry,
          yearEstablished: action.payload.businessInfo.yearEstablished,
          businessAlias: action.payload.businessInfo.businessAlias,
          homepage: action.payload.businessInfo.homepage,
        } as userProfileApi.UpdateBusinessInfoInput);
      }
      LocalStorage.setItem(LocalStorageKeys.INITIAL_USER_PROFILE, userProfile);
      yield put(
        actions.setApiStatus(
          createUpdate(UserProfileApiRequestTypes.CreateUserProfile, {
            status: ApiCallStatus.Success,
            userProfile: userProfile as Types.UserProfile,
          })
        )
      );
    } else {
      throw new Error('Current user undefined');
    }
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.CreateUserProfile, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* fetchUserProfile() {
  try {
    const userProfileResult: Types.UserProfileResult = yield call(
      userProfileApi.getUserProfile
    );
    yield put(actions.fetchUserProfileSuccess(userProfileResult));
  } catch (e) {
    yield put(actions.fetchUserProfileFailure(e));
  }
}

function* updateUserOnboarded(
  action: ActionType<typeof actions.updateUserOnboarded>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UpdateUserOnboarded, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const userProfile: Types.UserProfile = yield call(
      userProfileApi.updateUserOnboarded,
      action.payload
    );

    yield put(
      actions.updateUserProfile({
        onboarded: userProfile.onboarded,
      })
    );
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UpdateUserOnboarded, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UpdateUserOnboarded, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* updateBusinessInfo(
  action: ActionType<typeof actions.updateBusinessInfo>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UpdateBusinessInfo, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const selectedCompany: Types.SelectedCompany = yield call(
      userProfileApi.updateCompanyBusinessInfo,
      action.payload
    );

    yield put(
      actions.updateCompany({
        businessInfo: selectedCompany.businessInfo,
        verification: selectedCompany.verification,
      })
    );

    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UpdateBusinessInfo, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UpdateBusinessInfo, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* uploadVerificationDocument(
  action: ActionType<typeof actions.uploadVerificationDocument>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UploadVerificationDocument, {
          status: ApiCallStatus.Pending,
        })
      )
    );

    const profile = (yield select(
      selectors.getUserProfile
    )) as Types.UserProfile;

    const preSignedUrl: string = yield call(
      userProfileApi.getVerificationDocumentPreSignedUrl,
      action.payload
    );

    yield call(uploadFile, preSignedUrl, action.payload, {
      type: action.payload.type,
      name: action.payload.name,
    });

    const selectedCompany: Types.SelectedCompany = yield call(
      userProfileApi.updateBusinessVerificationStatus,
      {
        companyID: profile.selectedCompany!.companyID,
        status: Types.VerificationStatus.VerificationPending,
      }
    );
    yield put(
      actions.updateCompany({
        verification: selectedCompany.verification,
      })
    );
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UploadVerificationDocument, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(UserProfileApiRequestTypes.UploadVerificationDocument, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* uploadBusinessUserProfileImage(
  action: ActionType<typeof actions.uploadBusinessUserProfileImage>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(
          UserProfileApiRequestTypes.UploadBusinessUserProfileImage,
          {
            status: ApiCallStatus.Pending,
          }
        )
      )
    );

    const dataUrl = action.payload.cropImageDataUrl;
    const imageFileInfo: FileInfo | undefined =
      getTimeStampedFileInfoFromDataUrl(dataUrl, 'original');
    if (!imageFileInfo) {
      throw new Error(
        'image data url must be defined while uploading original image data'
      );
    }
    const {
      readUrl,
      writeUrl: signedUrl,
    }: Types.BusinessUserProfileImagePresignedUrls = yield call(
      userProfileApi.getBusinessProfileImagePreSignedUrl,
      {
        contentType: imageFileInfo.type,
        filenameWithExt: imageFileInfo.name,
      }
    );
    const imageResources: Types.BusinessUserProfileImageUrl = {
      writeUrl: signedUrl.split('?')[0],
      readUrl: readUrl,
    };
    yield call(uploadImageFile, signedUrl, dataUrl);
    yield put(
      actions.updateCompany({
        logo: {
          readUrl: imageResources.readUrl,
          writeUrl: imageResources.writeUrl,
        },
      })
    );
    yield put(
      actions.setApiStatus(
        createUpdate(
          UserProfileApiRequestTypes.UploadBusinessUserProfileImage,
          {
            status: ApiCallStatus.Success,
          }
        )
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(
          UserProfileApiRequestTypes.UploadBusinessUserProfileImage,
          {
            status: ApiCallStatus.Error,
            error: e,
          }
        )
      )
    );
  }
}

async function uploadImageFile(presignedURL: string, dataURL: string) {
  if (!isBlankOrUndefined(presignedURL)) {
    return await uploadDataUri(presignedURL, dataURL);
  }
  return Promise.resolve('Keep on trucking!');
}

const businessInfoNeedsUpdate = (
  oldModel: Types.BusinessInfo,
  newModel: Types.BusinessInfo
): boolean => {
  return !_isEqual(oldModel, newModel);
};

export default function* saga() {
  yield all([
    takeLeading(actions.createUserProfile.type, createUserProfile),
    takeLeading(actions.fetchUserProfile.type, fetchUserProfile),
    takeLeading(actions.updateBusinessInfo.type, updateBusinessInfo),
    takeLeading(actions.updateUserOnboarded.type, updateUserOnboarded),
    takeLeading(
      actions.uploadVerificationDocument.type,
      uploadVerificationDocument
    ),
    takeLeading(
      actions.uploadBusinessUserProfileImage.type,
      uploadBusinessUserProfileImage
    ),
  ]);
}
