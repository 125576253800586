import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  take,
  fork,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import firebase from 'firebase/app';
import { ActionType } from 'deox';
import * as actions from './auth.actions';
import {
  FirebaseAuth,
  FirebaseAuthResponse,
  FirebaseAuthVoidResponse,
  getCurrentUser,
  reloadUser,
} from '../../firebaseHelpers/firebaseHelpers';
import * as sharedActions from '../shared/shared.actions';
import * as userProfileActions from '../userProfile/userProfile.actions';
import * as userTracingActions from '../userTracing/userTracing.actions';
import * as billingActions from '../billing/billing.actions';
import { AuthApiRequestTypes, createUpdate } from './auth.constants';
import { ApiCallStatus, LocalStorageKeys } from '../shared/shared.constants';
// import {
//   getCurrentUserAgent,
//   UserAgentInformation,
// } from '../../utils/UserInfo';
import Routes from '../../routes';
import { push } from 'connected-react-router';
import * as userProfile from '../userProfile/userProfile';
import { urlPathAppend } from '../../utils/StringHelpers';
import { Types } from '../../api';
import { LocalStorage } from '../../utils/LocalStorage';
import { BillingAccount } from '../../api/billing.api';
import { UserTrace } from '../../api/userTracing.api';

const throwIfNonNull = (error: any) => {
  if (error) {
    throw error;
  }
};

function* waitForFetchUserLogInStartupData() {
  yield all([
    take([
      userTracingActions.fetchUserTraceFailure.type,
      userTracingActions.fetchUserTraceSuccess.type,
    ]),
    take([
      userProfile.actions.fetchUserProfileSuccess.type,
      userProfile.actions.fetchUserProfileFailure.type,
    ]),
    take([
      billingActions.fetchBillingFailure.type,
      billingActions.fetchBillingSuccess.type,
    ]),
    put(userProfile.actions.fetchUserProfile()),
    put(billingActions.fetchBilling()),
    put(userTracingActions.fetchUserTrace()),
  ]);
}

function* createAccountRequested(
  action: ActionType<typeof actions.createAccountRequest>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.CreateAccount, {
          status: ApiCallStatus.Pending,
        })
      )
    );
    const { email, password } = action.payload;
    const {
      error: createUserWithEmailPasswordError,
    }: FirebaseAuthResponse<firebase.User> = yield call(
      FirebaseAuth.createUserWithEmailPassword,
      email,
      password
    );
    throwIfNonNull(createUserWithEmailPasswordError);
    const { error: sendEmailVerificationError } = yield call(
      FirebaseAuth.sendEmailVerification,
      // TODO: change below to use swaymee address and not rely on location origin
      urlPathAppend(window.location.origin, Routes.SignUpFlow)
    );
    throwIfNonNull(sendEmailVerificationError);
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.CreateAccount, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.CreateAccount, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* sendPasswordResetRequested(
  action: ActionType<typeof actions.sendPasswordReset>
) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.PasswordReset, {
          status: ApiCallStatus.Pending,
        })
      )
    );
    const { error: sendPasswordResetError } = yield call(
      FirebaseAuth.sendPasswordReset,
      action.payload,
      // TODO: change below to use swaymee address and not rely on location origin
      urlPathAppend(window.location.origin, Routes.LogIn)
    );
    throwIfNonNull(sendPasswordResetError);
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.PasswordReset, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.PasswordReset, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* logInRequested(action: ActionType<typeof actions.logInRequest>) {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.LogIn, {
          status: ApiCallStatus.Pending,
        })
      )
    );
    // TODO: Only handle if user has onboarded
    // const userAgent: UserAgentInformation = yield call(getCurrentUserAgent);
    const { email, password } = action.payload;
    const { error }: FirebaseAuthVoidResponse = yield call(
      FirebaseAuth.logInWithEmailPassword,
      email,
      password
    );
    throwIfNonNull(error);
    yield call(waitForFetchUserLogInStartupData);
    // TODO: Only handle if user has onboarded
    // yield put(userTracingActions.updateHTTPInfo(userAgent));
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.LogIn, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.LogIn, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* deleteAccount() {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.DeleteAccount, {
          status: ApiCallStatus.Pending,
        })
      )
    );
    const { error }: FirebaseAuthVoidResponse = yield call(
      FirebaseAuth.deleteAccount
    );
    throwIfNonNull(error);
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.DeleteAccount, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.DeleteAccount, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

function* logout() {
  try {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.LogOut, {
          status: ApiCallStatus.Pending,
        })
      )
    );
    const { error }: FirebaseAuthVoidResponse = yield call(FirebaseAuth.logout);
    throwIfNonNull(error);
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.LogOut, {
          status: ApiCallStatus.Success,
        })
      )
    );
  } catch (e) {
    yield put(
      actions.setApiStatus(
        createUpdate(AuthApiRequestTypes.LogOut, {
          status: ApiCallStatus.Error,
          error: e,
        })
      )
    );
  }
}

const createFirebaseAuthChangeChannel = () => {
  return eventChannel<{ user: firebase.User | null }>((emit) => {
    const authChange = (user: firebase.User | null) => {
      emit({ user });
    };
    const unsubscribeThunk =
      (firebaseUnsubscribe: firebase.Unsubscribe) => () => {
        firebaseUnsubscribe();
      };
    const firebaseUnsubscribe = firebase.auth().onAuthStateChanged(authChange);
    if (getCurrentUser()) {
      authChange(getCurrentUser());
    }
    return unsubscribeThunk(firebaseUnsubscribe);
  });
};

function* handleFirebaseAuthChange() {
  const firebaseAuthChannel = createFirebaseAuthChangeChannel();
  yield take(firebaseAuthChannel);
  let currentUser = getCurrentUser();
  if (currentUser) {
    const [, fetchUserProfileResult]: [
      any,
      ActionType<
        | typeof userProfileActions.fetchUserProfileFailure
        | typeof userProfileActions.fetchUserProfileSuccess
      >
    ] = yield all([
      put(userProfileActions.fetchUserProfile()),
      take([
        userProfileActions.fetchUserProfileFailure.type,
        userProfileActions.fetchUserProfileSuccess.type,
      ]),
    ]);
    if (currentUser && !currentUser.emailVerified) {
      yield call(reloadUser);
      currentUser = yield call(getCurrentUser);
    }

    if (
      currentUser?.emailVerified &&
      fetchUserProfileResult.type === 'userProfile/fetchUserProfileSuccess' &&
      fetchUserProfileResult.payload.__typename === 'UserProfileNotFound'
    ) {
      yield put(push(Routes.SignUpFlow));
    } else {
      const userProfile =
        fetchUserProfileResult.payload as Types.UserProfileResult;
      // TODO: should be done differently. but fine for now.
      const [, , fetchBillingResult, fetchUserTraceResult]: [
        any,
        any,
        ActionType<
          | typeof billingActions.fetchBillingSuccess
          | typeof billingActions.fetchBillingFailure
        >,
        ActionType<
          | typeof userTracingActions.fetchUserTraceSuccess
          | typeof userTracingActions.fetchUserTraceFailure
        >
      ] = yield all([
        take([
          billingActions.fetchBillingFailure.type,
          billingActions.fetchBillingSuccess.type,
        ]),
        take([
          userTracingActions.fetchUserTraceFailure.type,
          userTracingActions.fetchUserTraceSuccess.type,
        ]),
        put(billingActions.fetchBilling()),
        put(userTracingActions.fetchUserTrace()),
      ]);
      if (userProfile.__typename === 'UserProfile' && !userProfile?.onboarded) {
        LocalStorage.setItem(
          LocalStorageKeys.INITIAL_USER_PROFILE,
          userProfile as Types.UserProfile
        );

        if (billingActions.isFetchBillingSuccess(fetchBillingResult)) {
          const billingAccount = fetchBillingResult.payload as BillingAccount;
          LocalStorage.setItem(
            LocalStorageKeys.INITIAL_USER_BILLING_ACCOUNT,
            billingAccount
          );
        }
        if (userTracingActions.isFetchUserTraceSuccess(fetchUserTraceResult)) {
          const userTrace = fetchUserTraceResult.payload as UserTrace;
          LocalStorage.setItem(LocalStorageKeys.INITIAL_USER_TRACE, userTrace);
        }
      }
    }
  }
  yield put(actions.authPreProcessingComplete());
}

function* onInitialized() {
  yield put(actions.authPreProcessingStart());
  yield fork(handleFirebaseAuthChange);
}

export default function* saga() {
  yield all([
    takeEvery(actions.logInRequest.type, logInRequested),
    takeEvery(actions.createAccountRequest.type, createAccountRequested),
    takeEvery(actions.sendPasswordReset.type, sendPasswordResetRequested),
    takeLatest(actions.logout.type, logout),
    takeLatest(actions.deleteAccount.type, deleteAccount),
    takeEvery(sharedActions.initialized.type, onInitialized),
  ]);
}
